@import "../fonts";
@import "../colors";

.cal-item-box {
  display: flex;
  background-color: $navigationBackground;
  padding: 8px;
  flex-direction: column;
  width: 100%;
}

.cal-item-box__title {
  font-weight: bold;
  font-size: 11pt;
  color: $black;
}

.cal-item-box__time {
  font-style: italic;
  color: $black;
}

.cal-item-box__rooms {
  display: inline-block;
  white-space: pre-wrap;
  font-size: 8pt;
}

thead > tr > .fc-theme-standard {
  border: none !important;
}

.fc-col-header thead {
  background-color: $darkAccentColor;

  a {
    color: $white !important;
  }
}

.fc .fc-daygrid-day-frame {
  padding-left: 2px;
  padding-right: 2px;
}

.fc-theme-standard td, .fc-theme-standard th, .fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-scrollgrid-sync-table {
  height: unset !important;
}

.fc-view-harness {
  height: auto !important;
  min-height: 700px !important;
}