@import "../fonts";
@import "../colors";

.loading-wrapper {
  background-color: $navigationBackground;
  padding-top: 75px;
  padding-bottom: 75px;
  margin-top: 35px;
  border-radius: 10px;

  .loader {
    border: 8px solid $white;
    border-top: 8px solid $ceviBlue;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }

  .text-loader {
    text-align: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.small-loading-wrapper {
  background-color: $navigationBackground;
  opacity: 0.7;
  padding-top: 70px;
  padding-bottom: 35px;
  margin-top: 35px;
  border-radius: 10px;
  position: fixed;
  height: 50%;
  right: 20px;
  left: 270px;
  margin-bottom: 20px;

  .small-loader {
    border: 8px solid $white;
    border-top: 8px solid $ceviBlue;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }

  .text-loader {
    text-align: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      font-size: 12px;
    }
  }

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}