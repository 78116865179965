@font-face {
  font-family: 'OpenSans Light';
  src: url("fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: 'OpenSans Regular';
  src: url("fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: 'OpenSans Semibold';
  src: url("fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: 'OpenSans Bold';
  src: url("fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: 'OpenSans Extrabold';
  src: url("fonts/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: 'Roboto Light';
  src: url("fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: 'Roboto Bold';
  src: url("fonts/Roboto-Bold.ttf");
}