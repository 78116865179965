.main-app {
  padding: 20px;
}

.main-content-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.copyright {
  background-color: $ceviBlue;
  color: $white;
  padding: 10px;
  border-radius: 5px;
}

.img-404 {
  width: 100%;
}

.content {
  padding: 10px;
}