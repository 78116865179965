$black: #272328;
$white: #ffffff;
$ceviRed: #E0002D;
$ceviBlue: #13358E;
$ceviGreen: #66b821;
$darkBackground: #f0f0f0;
$textColor: #666565;
$darkAccentColor: #9B9B9B;
$textColor: $black;

$navigationBackground: #f0f0f0;
$tableColor: $navigationBackground;
$darkAccentColor: #9B9B9B;
$inputColor: $navigationBackground;
$buttonTextColor: #4A4A4A;